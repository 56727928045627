/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social-twitter': {
    width: 19,
    height: 15,
    viewBox: '0 0 19 15',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.515 14.5c6.767 0 10.468-5.387 10.468-10.058 0-.153 0-.305-.01-.457a7.32 7.32 0 001.835-1.83 7.562 7.562 0 01-2.112.556A3.58 3.58 0 0018.313.756a7.562 7.562 0 01-2.336.858C14.582.191 12.252.122 10.77 1.461c-.954.863-1.36 2.15-1.064 3.378-2.958-.143-5.714-1.485-7.583-3.693-.976 1.615-.477 3.68 1.14 4.718a3.766 3.766 0 01-1.67-.442v.045c0 1.682 1.235 3.13 2.951 3.464a3.805 3.805 0 01-1.66.06c.481 1.44 1.863 2.428 3.436 2.456a7.576 7.576 0 01-4.569 1.516 7.88 7.88 0 01-.876-.05 10.732 10.732 0 005.64 1.584" _fill="#fff" fill-opacity=".4"/>'
  }
})
